export const dailyWorkReportStore = {
  namespaced: true,
  state: {
    nippoType: null
  } as DailyWorkReportStore,
  mutations: {
    nippoTypeSet(state: DailyWorkReportStore, _nippoType: string | number) {
      state.nippoType = String(_nippoType);
    }
  }
};

type DailyWorkReportStore = {
  nippoType: string | null;
};
