import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ja from "vuetify/src/locale/ja";

Vue.config.productionTip = false;
Vue.use(Vuetify);

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { ja },
      current: "ja"
    }
  }),
  render: h => h(App)
}).$mount("#app");
