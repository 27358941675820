import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import Login from "@/models/Login";
import LoginResponse from "@/models/LoginResponse";
import { ErrorResponse } from "@/models/ErrorResponse";

import Store from "@/store/index";

const BaseURL = process.env.VUE_APP_API_BASE_URL;
//const BaseURL = "http://hgs225:10001/aries/api";
//const BaseURL = "https://www.hgswp01.com/aries/api";
//const BaseURL = "https://shikoku-fuji-report.com/aries/api";

function getToken() {
  return Store.state.token;
}

function getManagerToken() {
  return Store.state.mtoken;
}

function createHttpClient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken()
    }
  });
}

function createDownloadExcelHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "application/zip"
    },
    responseType: "blob"
  });
}

function createDownloadCSVHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "text/csv"
    },
    responseType: "blob"
  });
}

function getUrl(baseUrl: string, params: string | string[] = ""): string {
  // const param = params
  //   ? params instanceof String
  //     ? params
  //     : (params as string[]).join("/")
  //   : "";
  // const param = params ? params : "";
  const url = params
    ? baseUrl + (baseUrl.match("^.*/$") ? "" : "?") + params
    : baseUrl + (baseUrl.match("^.*/$") ? "" : "/");
  return url;
}

const dailyWorkReportPaths = {
  search: "/dailyworkreport/search",
  tableList: "/dailyworkreport/table/list",
  tableCode: "/dailyworkreport/table/code",
  yorisen: {
    search: "/yorisen/search",
    searchKey: "/yorisen/search/key",
    insert: "/yorisen/insert",
    update: "/yorisen/update",
    delete: "/yorisen/delete",
    code: "/yorisen/code"
  },
  hakuen: {
    search: "/hakuen/search",
    searchKey: "/hakuen/search/key",
    insert: "/hakuen/insert",
    update: "/hakuen/update",
    delete: "/hakuen/delete",
    code: "/hakuen/code"
  },
  hien: {
    search: "/hien/search",
    searchKey: "/hien/search/key",
    insert: "/hien/insert",
    update: "/hien/update",
    delete: "/hien/delete",
    code: "/hien/code"
  },
  shukka: {
    search: "/shukka/search",
    searchKey: "/shukka/search/key",
    insert: "/shukka/insert",
    update: "/shukka/update",
    delete: "/shukka/delete",
    code: "/shukka/code"
  },
  rubber: {
    search: "/rubber/search",
    searchKey: "/rubber/search/key",
    insert: "/rubber/insert",
    update: "/rubber/update",
    delete: "/rubber/delete",
    code: "/rubber/code"
  },
  repair: {
    search: "/repair/search",
    searchKey: "/repair/search/key",
    insert: "/repair/insert",
    update: "/repair/update",
    delete: "/repair/delete",
    code: "/repair/code"
  },
  vulcanizing: {
    search: "/vulcanizing/search",
    searchKey: "/vulcanizing/search/key",
    insert: "/vulcanizing/insert",
    update: "/vulcanizing/update",
    delete: "/vulcanizing/delete",
    code: "/vulcanizing/code"
  },
  assembly: {
    search: "/assembly/search",
    searchKey: "/assembly/search/key",
    insert: "/assembly/insert",
    update: "/assembly/update",
    delete: "/assembly/delete",
    code: "/assembly/code"
  },
  assemblyThinwire: {
    search: "/assembly-thinwire/search",
    searchKey: "/assembly-thinwire/search/key",
    insert: "/assembly-thinwire/insert",
    update: "/assembly-thinwire/update",
    delete: "/assembly-thinwire/delete",
    code: "/assembly-thinwire/code"
  },
  shield: {
    search: "/shield/search",
    searchKey: "/shield/search/key",
    insert: "/shield/insert",
    update: "/shield/update",
    delete: "/shield/delete",
    code: "/shield/code"
  },
  sSuzu: {
    search: "/s-suzu/search",
    searchKey: "/s-suzu/search/key",
    insert: "/s-suzu/insert",
    update: "/s-suzu/update",
    delete: "/s-suzu/delete",
    code: "/s-suzu/code"
  },
  sHiki: {
    search: "/s-hiki/search",
    searchKey: "/s-hiki/search/key",
    insert: "/s-hiki/insert",
    update: "/s-hiki/update",
    delete: "/s-hiki/delete",
    code: "/s-hiki/code"
  },
  sSyugo: {
    search: "/s-syugo/search",
    searchKey: "/s-syugo/search/key",
    insert: "/s-syugo/insert",
    update: "/s-syugo/update",
    delete: "/s-syugo/delete",
    code: "/s-syugo/code"
  },
  kGaikan: {
    search: "/k-gaikan/search",
    searchKey: "/k-gaikan/search/key",
    insert: "/k-gaikan/insert",
    update: "/k-gaikan/update",
    delete: "/k-gaikan/delete",
    code: "/k-gaikan/code"
  },
  kDenki: {
    search: "/k-denki/search",
    searchKey: "/k-denki/search/key",
    insert: "/k-denki/insert",
    update: "/k-denki/update",
    delete: "/k-denki/delete",
    code: "/k-denki/code"
  },
  isolation: {
    search: "/isolation/search",
    searchKey: "/isolation/search/key",
    insert: "/isolation/insert",
    update: "/isolation/update",
    delete: "/isolation/delete",
    code: "/isolation/code"
  },
  sheath: {
    search: "sheath/search",
    searchKey: "sheath/search/key",
    insert: "sheath/insert",
    update: "sheath/update",
    delete: "sheath/delete",
    code: "sheath/code"
  },
  machining: {
    search: "machining/search",
    searchKey: "machining/search/key",
    insert: "machining/insert",
    update: "machining/update",
    delete: "machining/delete",
    code: "machining/code"
  },
  hcvRollIsolation: {
    search: "hcv-roll-isolation/search",
    searchKey: "hcv-roll-isolation/search/key",
    insert: "hcv-roll-isolation/insert",
    update: "hcv-roll-isolation/update",
    delete: "hcv-roll-isolation/delete",
    code: "hcv-roll-isolation/code"
  },
  hcvRollUpper: {
    search: "hcv-roll-upper/search",
    searchKey: "hcv-roll-upper/search/key",
    insert: "hcv-roll-upper/insert",
    update: "hcv-roll-upper/update",
    delete: "hcv-roll-upper/delete",
    code: "hcv-roll-upper/code"
  }
} as const;

const vulcanizingLinkagePaths = {
  search: "/vulcanizing-linkage/search",
  tableList: "/vulcanizing-linkage/table/list"
} as const;

const orderDetailsPaths = {
  search: "/order-details/search"
} as const;

const specDocsPaths = {
  searchkey: "/specdocs/search/key"
} as const;

const Paths = {
  atuhShozoku: "/auth/shozoku",
  authManager: "/auth/manager",
  authTemporary: "/auth/temporary",
  shain1: "/daijin/shain1",
  shozoku: "/daijin/shozoku",
  authinfoTemporary: "/authinfo/temporary",
  codeGroup: "/codegroup",
  codeGroupPulldown: "/codegroup/pulldown",
  codeMaster: "/code-master",
  codeMasterPulldown: "/code-master/pulldown",
  shozokuQr: "/daijin/shozoku/qr",
  shainQr: "/daijin/shain1/qr",
  authinfo: "/authinfo",
  authinfoBulk: "/authinfo/bulk",
  shainSearch: "shain/search",
  shainPulldown: "/shain/pulldown",
  shainPulldownValid: "/shain/pulldown/valid",
  shainSorted: "/daijin/shain1/sorted",
  authNomalUser: "/auth/user",
  shain: "/shain",
  codeMasterPulldownAutho: "/code-master/pulldown-auth",
  dailyWorkReport: dailyWorkReportPaths,
  vulcanizingLinkage: vulcanizingLinkagePaths,
  orderDetails: orderDetailsPaths,
  specDocs: specDocsPaths
} as const;

export default Vue.extend({
  name: "Api",
  data() {
    return {
      Paths
    };
  },
  methods: {
    async $auth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr ? "1" : "0"
      };

      let path = "";
      switch (payload.selectTab) {
        case 0:
          path = Paths.atuhShozoku;
          break;
        case 1:
          path = Paths.authManager;
          break;
        default:
          path = Paths.authNomalUser;
          break;
      }

      return await this.$post(path, data);
    },
    async $tmpauth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr
      };

      return await this.$post(Paths.authTemporary, data);
    },
    async $verify(payload: Login): Promise<any> {
      console.log("auth", payload);

      const token = getToken();

      if (token) {
        Store.state.name = "ダミー";
        Store.state.login = true;
      }

      return !!token;
    },
    /**
     * Get Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $get<T = any>(
      path: string,
      params: string | string[] | undefined = undefined,
      query: any | undefined = undefined
    ): Promise<T> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      try {
        const result = await client.get<T>(url, { params: query });

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    },

    async $downloadexcel(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createDownloadExcelHttpCLient();

      const url = getUrl(path, params);

      try {
        const result = await client.get(url, { params: query });

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    },
    async $downloadcsv(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createDownloadCSVHttpCLient();

      const url = getUrl(path, params);
      try {
        const result = await client.get(url, { params: query });

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    },
    /**
     * POST Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $post<T = any>(
      path: string,
      data: any,
      params: string | string[] = ""
    ): Promise<T> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("POST: " + url + " &" + JSON.stringify(data));

      try {
        const result = await client.post<T>(url, data);

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    },
    /**
     * PUT Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $put(
      path: string,
      data: any,
      params: string | string[]
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("PUT: " + url + " &" + JSON.stringify(data));

      try {
        const result = await client.put(url, data);

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    },

    /**
     * DELETE Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $del(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("DELETE: " + url + "?" + JSON.stringify(query));

      try {
        const result = await client.delete(url, { params: query });

        if (ErrorResponse.isValid(result.data)) {
          console.error(result.data.error);
          throw new Error(result.data.error.errorMessage);
        }

        console.log(result);
        return result.data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          throw new Error(e.response?.data.message);
        }
        throw new Error("予期せぬエラーが発生しました。");
      }
    }
  }
});
