<template>
  <div class="home">
    <!-- AppBar -->
    <v-app-bar color="teal lighten-3" dark clipped-left app class="no-print">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $store.state.title ? $store.state.title : ""
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- メインアバターチップ -->
      <v-chip class="ma-2" color="primary" text-color="white">
        <v-avatar v-if="$store.state.type === UserType.Office" left>
          <v-icon>mdi-account</v-icon>
        </v-avatar>
        <v-avatar v-else left class="blue darken-4">
          {{ $store.state.name.charAt(0) }}
        </v-avatar>
        {{ $store.state.name }}
      </v-chip>
    </v-app-bar>

    <!-- Drawer -->
    <Menu v-model="drawer" />

    <!-- Main -->
    <v-main class="v-main">
      <v-container fluid>
        <!-- <a 
          href="./ServiceRecordMaintenance" 
          color="Link">
          <strong>TEST</strong>
        </a> -->
        <router-view />
      </v-container>
    </v-main>
    <!-- Main End -->
  </div>
</template>

<script>
// @ is an alias to /src
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";

import UserType from "@/commons/usertype";

import TmpLogin from "./dialogs/TemporaryLogin";

import menus from "@/commons/menus";

import Menu from "@/components/menu/Menu.vue";

export default {
  name: "Home",
  mixins: [Common, ShowDialogs],
  components: {
    Menu
  },
  data() {
    return {
      UserType,
      drawer: false,
      menus
    };
  },
  methods: {
    logout() {
      console.log("logout");
    },
    click(e) {
      console.log(e);
    },
    async tmpLogin() {
      console.log(this.$store);
      if (!this.$store.state.user.login) await this.$showDialog(TmpLogin);
      else if (await this.$confirm("ログアウトしますか？"))
        this.$store.commit("user/logout");
    }
  },
  created() {
    console.log("vuetify.breakpoint", this.$vuetify.breakpoint);
    console.log(menus);
  }
};
</script>
<style scoped>
.home {
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  max-width: 100%;
  position: relative;
}
.v-main {
  height: 100%;
}
</style>
<style lang="scss">
.v-main__wrap {
  display: flex;
  overflow: auto;
}
</style>
