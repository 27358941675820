import { CodeKbnType } from "@/models/codeMaster/Code";

export class PulldownResponse {
  code: string;
  name: string;
  refcode: string | null;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  constructor(_props: PulldownResponse) {
    this.code = _props.code;
    this.name = _props.name;
    this.refcode = _props.refcode;
    this.option1 = _props.option1;
    this.option2 = _props.option2;
    this.option3 = _props.option3;
  }
}

/**
 * プルダウンレスポンスを区分名で絞り込み
 * @param pulldown プルダウンレスポンス
 * @param code 区分名
 * @returns プルダウンレスポンス
 */
export const codeKbnFilter = (
  pulldown: PulldownResponse[],
  code: CodeKbnType
) => {
  return pulldown.filter(e => e.refcode === code);
};
