import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Main",
        component: () => import("@/views/About.vue")
      },
      {
        path: "/office",
        name: "Office",
        meta: { title: "ホーム画面" },
        component: () => import("@/views/Office.vue")
      },
      {
        path: "/list/:name",
        name: "List",
        component: () => import("@/views/TableView/List.vue")
      },
      {
        path: "/details/shain",
        name: "ShainDetails",
        component: () => import("@/views/TableView/ShainDetails.vue")
      },
      {
        path: "/yorisen",
        name: "Yorisen",
        component: () => import("@/views/dailyWorkReport/Yorisen.vue"),
        props: true
      },
      {
        path: "/hakuen",
        name: "Hakuen",
        component: () => import("@/views/dailyWorkReport/Hakuen.vue"),
        props: true
      },
      {
        path: "/hien",
        name: "Hien",
        component: () => import("@/views/dailyWorkReport/Hien.vue"),
        props: true
      },
      {
        path: "/shukka",
        name: "Shukka",
        component: () => import("@/views/dailyWorkReport/Shukka.vue"),
        props: true
      },
      {
        path: "/rubber",
        name: "Rubber",
        component: () => import("@/views/dailyWorkReport/Rubber.vue"),
        props: true
      },
      {
        path: "/repair",
        name: "Repair",
        component: () => import("@/views/dailyWorkReport/Repair.vue"),
        props: true
      },
      {
        path: "/vulcanizing",
        name: "Vulcanizing",
        component: () => import("@/views/dailyWorkReport/Vulcanizing.vue"),
        props: true
      },
      {
        path: "/assembly",
        name: "Assembly",
        component: () => import("@/views/dailyWorkReport/Assembly.vue"),
        props: true
      },
      {
        path: "/assembly-thinwire",
        name: "AssemblyThinwire",
        component: () => import("@/views/dailyWorkReport/AssemblyThinwire.vue"),
        props: true
      },
      {
        path: "/shield",
        name: "Shield",
        component: () => import("@/views/dailyWorkReport/Shield.vue"),
        props: true
      },
      {
        path: "/s-suzu",
        name: "SSuzu",
        component: () => import("@/views/dailyWorkReport/SSuzu.vue"),
        props: true
      },
      {
        path: "/s-hiki",
        name: "SHiki",
        component: () => import("@/views/dailyWorkReport/SHiki.vue"),
        props: true
      },
      {
        path: "/s-syugo",
        name: "SSyugo",
        component: () => import("@/views/dailyWorkReport/SSyugo.vue"),
        props: true
      },
      {
        path: "/k-gaikan",
        name: "KGaikan",
        component: () => import("@/views/dailyWorkReport/KGaikan.vue"),
        props: true
      },
      {
        path: "/k-denki",
        name: "KDenki",
        component: () => import("@/views/dailyWorkReport/KDenki.vue"),
        props: true
      },
      {
        path: "/isolation/:machineCode",
        name: "Isolation",
        component: () => import("@/views/dailyWorkReport/Isolation.vue"),
        props: true
      },
      {
        path: "/sheath/:machineCode",
        name: "Sheath",
        component: () => import("@/views/dailyWorkReport/Sheath.vue"),
        props: true
      },
      {
        path: "/machining",
        name: "Machining",
        component: () => import("@/views/dailyWorkReport/Machining.vue"),
        props: true
      },
      {
        path: "/hcv-roll-isolation/:machineCode",
        name: "HcvRollIsolation",
        component: () => import("@/views/dailyWorkReport/HcvRollIsolation.vue"),
        props: true
      },
      {
        path: "/hcv-roll-upper/:machineCode",
        name: "HcvRollUpper",
        component: () => import("@/views/dailyWorkReport/HcvRollUpper.vue"),
        props: true
      },
      {
        path: "/test",
        name: "Test",
        component: () => import("@/views/dailyWorkReport/Test.vue")
      },
      {
        path: "/dailyworkreport/search",
        name: "DailyWorkReportSearch",
        component: () =>
          import("@/views/dailyWorkReport/DailyWorkReportSearch.vue"),
        props: true
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/debug",
        name: "Debug",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Debug.vue")
      },
      {
        path: "/authinfo",
        name: "AuthInfoList",
        meta: { title: "ログインアカウント" },
        component: () => import("@/views/AuthInfoList.vue")
      }
    ]
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

export default router;
