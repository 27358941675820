export class CodeKey {
  kbn: CodeKbnType;
  code: string;
  constructor(_props: { kbn: CodeKbnType; code: string }) {
    this.kbn = _props.kbn;
    this.code = _props.code;
  }
}

export class Code extends CodeKey {
  name: string | null;
  bikou: string | null;
  comcha1: string | null;
  comcha2: string | null;
  comcha3: string | null;
  comnum1: string | null;
  comnum2: string | null;
  comnum3: string | null;
  comdat1: string | null;
  comdat2: string | null;
  comdat3: string | null;
  delflg: string | null;
  sort: string | null;
  constructor(_props: Code);
  constructor(_props: CodeKey);
  constructor(_props: Code | CodeKey) {
    super(_props);
    if (_props instanceof Code) {
      this.name = _props.name;
      this.bikou = _props.bikou;
      this.comcha1 = _props.comcha1;
      this.comcha2 = _props.comcha2;
      this.comcha3 = _props.comcha3;
      this.comnum1 = _props.comnum1;
      this.comnum2 = _props.comnum2;
      this.comnum3 = _props.comnum3;
      this.comdat1 = _props.comdat1;
      this.comdat2 = _props.comdat2;
      this.comdat3 = _props.comdat3;
      this.delflg = _props.delflg;
      this.sort = _props.sort;
      return;
    }
    this.name = null;
    this.bikou = null;
    this.comcha1 = null;
    this.comcha2 = null;
    this.comcha3 = null;
    this.comnum1 = null;
    this.comnum2 = null;
    this.comnum3 = null;
    this.comdat1 = null;
    this.comdat2 = null;
    this.comdat3 = null;
    this.delflg = null;
    this.sort = null;
  }
}

export const CodeKbnList = {
  AUTHOR: "AUTHOR",
  COMPSK: "COMPSK",
  COMPTP: "COMPTP",
  CONDCT: "CONDCT",
  CONUSE: "CONUSE",
  GAIKAN: "GAIKAN",
  HAKUEN: "HAKUEN",
  HIEN: "HIEN",
  INTAPE: "INTAPE",
  LFTRGT: "LFTRGT",
  NIPPO: "NIPPO",
  OSDS: "OSDS",
  RUBBER: "RUBBER",
  SHUKKA: "SHUKKA",
  SINSEN: "SINSEN",
  SYUGO: "SYUGO",
  SYUSIN: "SYUSIN",
  SYUURI: "SYUURI",
  TPRAP: "TPRAP",
  VALID: "VALID",
  VCAN: "VCAN",
  VKILN: "VKILN",
  VPROC: "VPROC",
  YESNO: "YESNO",
  YORISN: "YORISN",
  SHIELD: "SHIELD",
  SUZUM1: "SUZUM1",
  SUZUM2: "SUZUM2",
  SSTA: "SSTA",
  SSFLEN: "SSFLEN",
  GORB: "GORB",
  SEPATP: "SEPATP",
  VSIZE: "VSIZE",
  SHIFT: "SHIFT",
  HCVCCV: "HCVCCV"
} as const;
export type CodeKbnType = typeof CodeKbnList[keyof typeof CodeKbnList];
