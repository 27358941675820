import { Vue } from "vue-property-decorator";

const Rules = {
  Required: (v: any, or: boolean) => or || !!v || "必須項目です。"
};

export default Vue.extend({
  name: "Forms",
  components: {},
  data() {
    return {
      valid: false,
      Rules
    };
  },
  computed: {},
  methods: {}
});
