<template>
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate :size="size"></v-progress-circular>
  </v-overlay>
</template>

<script>
import dialog from "@/mixins/dialog";

export default {
  name: "LoadingOverlay",
  mixins: [dialog],
  props: {
    value: Boolean,
    timeout: Number,
    size: { type: [String, Number], default: 64 }
  },
  data() {
    return {
      overlay: false
    };
  },
  methods: {
    close() {
      this.overlay = false;
      this.$close(false);
    }
  },
  mounted() {
    this.overlay = true;
    if (this.timeout) setTimeout(() => this.close(), this.timeout);
  }
};
</script>

<style></style>
