
import Vue, { PropType } from "vue";

type MenuGroupItem = {
  text: string;
  open: boolean;
  icon?: string;
  authority?: number[];
  children?: MenuChildren[];
};

type MenuItem = {
  text: string;
  name: string;
  params?: Record<string, unknown>;
  icon?: string;
  authority?: number[];
};

export type MenuChildren = MenuGroupItem | MenuItem;

export default Vue.extend({
  name: "MenuItems",
  components: {},
  props: {
    item: {
      type: Object as PropType<MenuChildren>,
      required: true
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  }
});
