
import Vue from "vue";
import { VList, VNavigationDrawer } from "vuetify/lib";
import Menu from "@/commons/menus";
import MenuItems, { MenuChildren } from "./MenuItems.vue";

import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { CodeKbnList } from "@/models/codeMaster/Code";
import {
  PulldownResponse,
  codeKbnFilter
} from "@/models/response/PulldownResponse";

const homeMenu: MenuChildren[] = [
  {
    name: "Office",
    icon: "mdi-briefcase-clock",
    text: "ホーム",
    authority: [0, 1, 2, 3, 5, 9]
  }
];

const masterMenu: MenuChildren[] = [
  {
    name: "List",
    icon: "mdi-account-multiple",
    text: "ユーザーマスタ",
    authority: [0, 1, 2, 3, 5, 9],
    params: {
      name: "shain"
    }
  },
  {
    name: "List",
    icon: "mdi-alphabetical-variant",
    text: "コードマスタ",
    authority: [0, 1, 2, 3, 5, 9],
    params: {
      name: "code-master"
    }
  }
];

const systemMenu: MenuChildren[] = [
  {
    open: false,
    text: "システム管理",
    icon: "mdi-account-cowboy-hat",
    authority: [9],
    children: [
      {
        name: "List",
        icon: "mdi-database",
        text: "コードグループ",
        authority: [9],
        params: {
          name: "code-group"
        }
      },
      {
        text: "デバッグ",
        icon: "mdi-vuejs",
        name: "/Debug",
        authority: [9]
      }
    ]
  },
  {
    text: "ログアウト",
    icon: "mdi-logout",
    name: "Login",
    authority: [0, 1, 2, 3, 4, 5, 9]
  }
];

export default Vue.extend({
  name: "Menu",
  components: {
    VList,
    VNavigationDrawer,
    MenuItems
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      items: Menu as MenuChildren[],
      machineCode: [] as PulldownResponse[],
      hcvccvMachine: [] as PulldownResponse[],
      isolationMachine: 4,
      sheathMachine: 5
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    },
    isolationMenu: {
      get(): MenuChildren[] {
        const result: MenuChildren[] = [];
        for (const [i, code] of this.machineCode.entries()) {
          if (this.isolationMachine === i) break;
          result.push({
            name: "Isolation",
            icon: "mdi-notebook",
            text: code.name,
            authority: [0, 1, 2, 3, 5, 9],
            params: {
              machineCode: code.code,
              machineName: code.name
            }
          });
        }
        return result;
      }
    },
    sheathMenu: {
      get(): MenuChildren[] {
        const result: MenuChildren[] = [];
        for (const [i, code] of this.machineCode.entries()) {
          if (this.sheathMachine === i) break;
          result.push({
            name: "Sheath",
            icon: "mdi-notebook",
            text: code.name,
            authority: [0, 1, 2, 3, 5, 9],
            params: {
              machineCode: code.code,
              machineName: code.name
            }
          });
        }
        return result;
      }
    },
    hcvRollIsolationMenu: {
      get(): MenuChildren[] {
        return this.hcvccvMachine.map(e => ({
          name: "HcvRollIsolation",
          icon: "mdi-notebook",
          text: e.name,
          authority: [0, 1, 2, 3, 5, 9],
          params: { machineCode: e.code, machineName: e.name }
        }));
      }
    },
    hcvRollUpperMenu: {
      get(): MenuChildren[] {
        return this.hcvccvMachine.map(e => ({
          name: "HcvRollUpper",
          icon: "mdi-notebook",
          text: e.name,
          authority: [0, 1, 2, 3, 5, 9],
          params: { machineCode: e.code, machineName: e.name }
        }));
      }
    },
    dailyworkreportMenu: {
      get(): MenuChildren[] {
        return [
          {
            open: false,
            text: "日報入力",
            icon: "mdi-draw-pen",
            authority: [1, 2, 3, 4, 5, 9],
            children: [
              {
                open: false,
                text: "伸線作業記録",
                authority: [1, 2, 3, 4, 5, 9],
                children: [
                  {
                    text: "引き落とし作業記録",
                    icon: "mdi-notebook",
                    name: "SHiki",
                    authority: [1, 2, 3, 4, 5, 9]
                  },
                  {
                    text: "錫引き作業記録",
                    icon: "mdi-notebook",
                    name: "SSuzu",
                    authority: [1, 2, 3, 4, 5, 9]
                  },
                  {
                    text: "集合作業記録",
                    icon: "mdi-notebook",
                    name: "SSyugo",
                    authority: [1, 2, 3, 4, 5, 9]
                  }
                ]
              },
              {
                text: "集合作業記録",
                icon: "mdi-notebook",
                name: "Assembly",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "集合(細線)作業記録",
                icon: "mdi-notebook",
                name: "AssemblyThinwire",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "撚線作業記録",
                icon: "mdi-notebook",
                name: "Yorisen",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                open: false,
                text: "絶縁押出(低圧)作業記録",
                authority: [1, 2, 3, 4, 5, 9],
                children: this.isolationMenu
              },
              {
                open: false,
                text: "シース押出(低圧)作業記録",
                authority: [1, 2, 3, 4, 5, 9],
                children: this.sheathMenu
              },
              {
                open: false,
                text: "HCV-CCV巻取作業記録",
                authority: [1, 2, 3, 4, 5, 9],
                children: [
                  {
                    open: false,
                    text: "絶縁作業記録",
                    authority: [1, 2, 3, 4, 5, 9],
                    children: this.hcvRollIsolationMenu
                  },
                  {
                    open: false,
                    text: "上出し作業記録",
                    authority: [1, 2, 3, 4, 5, 9],
                    children: this.hcvRollUpperMenu
                  }
                ]
              },
              {
                text: "加硫作業記録",
                icon: "mdi-notebook",
                name: "Vulcanizing",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "被鉛作業記録",
                icon: "mdi-notebook",
                name: "Hien",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "剝鉛作業記録",
                icon: "mdi-notebook",
                name: "Hakuen",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "ゴム練り作業記録",
                icon: "mdi-notebook",
                name: "Rubber",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                open: false,
                text: "検査作業記録",
                authority: [1, 2, 3, 4, 5, 9],
                children: [
                  {
                    text: "外観検査作業記録",
                    icon: "mdi-notebook",
                    name: "KGaikan",
                    authority: [1, 2, 3, 4, 5, 9]
                  },
                  {
                    text: "電気試験作業記録",
                    icon: "mdi-notebook",
                    name: "KDenki",
                    authority: [1, 2, 3, 4, 5, 9]
                  }
                ]
              },
              {
                text: "修理作業記録",
                icon: "mdi-notebook",
                name: "Repair",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "出荷班作業記録",
                icon: "mdi-notebook",
                name: "Shukka",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "加工作業記録",
                icon: "mdi-notebook",
                name: "Machining",
                authority: [1, 2, 3, 4, 5, 9]
              },
              {
                text: "シールド作業記録",
                icon: "mdi-notebook",
                name: "Shield",
                authority: [1, 2, 3, 4, 5, 9]
              }
            ]
          },
          {
            name: "DailyWorkReportSearch",
            icon: "mdi-draw-pen",
            text: "日報検索",
            authority: [0, 1, 2, 3, 5, 9]
          }
        ];
      }
    },
    menu: {
      get(): MenuChildren[] {
        return [
          ...homeMenu,
          ...this.dailyworkreportMenu,
          ...masterMenu,
          ...systemMenu
        ];
      }
    }
  },
  methods: {
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const codeMasterPath = api.Paths.codeMasterPulldown;
        const codeMaster = await api.$get<PulldownResponse[]>(codeMasterPath);

        //コードマスタのデータをすべて取得
        //絶縁・シース機械名
        this.machineCode = codeKbnFilter(codeMaster, CodeKbnList.OSDS);
        this.hcvccvMachine = codeKbnFilter(codeMaster, CodeKbnList.HCVCCV);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    }
  },
  async mounted() {
    await this.getItems();
  }
});
